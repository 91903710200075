import React from 'react';

const RemoteWorkIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path opacity="0.5"
          d="M14 22H10C6.229 22 4.343 22 3.172 20.788C2.001 19.576 2 17.626 2 13.725V12.204C2 9.915 2 8.771 2.52 7.823C3.038 6.874 3.987 6.286 5.884 5.108L7.884 3.867C9.889 2.622 10.892 2 12 2C13.108 2 14.11 2.622 16.116 3.867L18.116 5.108C20.013 6.286 20.962 6.874 21.481 7.823C22 8.772 22 9.915 22 12.203V13.725C22 17.625 22 19.576 20.828 20.788C19.656 22 17.771 22 14 22Z"
          fill="#07132B"/>
    <path
      d="M17.45 12.192C14.433 8.936 9.56701 8.936 6.55001 12.192C6.48303 12.2642 6.40249 12.3226 6.31297 12.3637C6.22346 12.4048 6.12672 12.4278 6.02829 12.4315C5.8295 12.439 5.63588 12.3673 5.49001 12.232C5.41778 12.165 5.35945 12.0845 5.31835 11.995C5.27725 11.9054 5.25419 11.8087 5.25047 11.7103C5.24297 11.5115 5.31475 11.3179 5.45001 11.172C9.06001 7.276 14.94 7.276 18.55 11.172C18.6853 11.3179 18.757 11.5115 18.7495 11.7103C18.742 11.9091 18.6559 12.0967 18.51 12.232C18.3641 12.3673 18.1705 12.439 17.9717 12.4315C17.7729 12.424 17.5853 12.3379 17.45 12.192Z"
      fill="#07132B"/>
    <path
      d="M15.45 14.35C13.538 12.287 10.463 12.287 8.55002 14.35C8.48394 14.4253 8.40344 14.4866 8.31327 14.5303C8.2231 14.574 8.12509 14.5992 8.02503 14.6043C7.92497 14.6095 7.82488 14.5946 7.73068 14.5605C7.63649 14.5263 7.55008 14.4736 7.47658 14.4056C7.40308 14.3375 7.34396 14.2553 7.30273 14.164C7.26149 14.0727 7.23897 13.974 7.2365 13.8739C7.23402 13.7737 7.25164 13.6741 7.28831 13.5808C7.32499 13.4876 7.37997 13.4026 7.45002 13.331C9.95602 10.627 14.044 10.627 16.55 13.331C16.6853 13.4769 16.7571 13.6705 16.7496 13.8693C16.7421 14.0681 16.6559 14.2557 16.51 14.391C16.3642 14.5263 16.1705 14.598 15.9717 14.5905C15.773 14.583 15.5853 14.4959 15.45 14.35Z"
      fill="#07132B"/>
    <path
      d="M13.45 16.51C12.642 15.638 11.358 15.638 10.55 16.51C10.483 16.5822 10.4025 16.6406 10.313 16.6817C10.2235 16.7228 10.1267 16.7458 10.0283 16.7495C9.92986 16.7533 9.83166 16.7375 9.7393 16.7033C9.64694 16.6691 9.56223 16.617 9.49001 16.55C9.41778 16.483 9.35945 16.4025 9.31835 16.313C9.27725 16.2235 9.25419 16.1267 9.25047 16.0283C9.24676 15.9299 9.26247 15.8317 9.2967 15.7393C9.33094 15.6469 9.38303 15.5622 9.45001 15.49C10.851 13.978 13.149 13.978 14.55 15.49C14.6853 15.6359 14.757 15.8295 14.7495 16.0283C14.742 16.2271 14.6559 16.4147 14.51 16.55C14.3641 16.6853 14.1705 16.757 13.9717 16.7495C13.7729 16.742 13.5853 16.6559 13.45 16.51Z"
      fill="#07132B"/>
  </svg>
);

export default RemoteWorkIcon;
