import React from 'react';

const AccommodationIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.00244 12.2665C2.6221 12.4854 2.322 12.8248 2.15224 13.2346C2 13.6022 2 14.0681 2 15C2 15.9319 2 16.3978 2.15224 16.7654C2.35523 17.2554 2.74458 17.6448 3.23463 17.8478C3.48702 17.9523 3.78581 17.9851 4.25 17.9953V20C4.25 20.4142 4.58579 20.75 5 20.75C5.41421 20.75 5.75 20.4142 5.75 20V18H18.25V20C18.25 20.4142 18.5858 20.75 19 20.75C19.4142 20.75 19.75 20.4142 19.75 20V17.9953C20.2142 17.9851 20.513 17.9523 20.7654 17.8478C21.2554 17.6448 21.6448 17.2554 21.8478 16.7654C22 16.3978 22 15.9319 22 15C22 14.0681 22 13.6022 21.8478 13.2346C21.678 12.8248 21.3779 12.4854 20.9976 12.2666L19.25 12.0001L19 12H5L4.75003 12.0001L3.00244 12.2665Z"
      fill="#07132B"/>
    <path opacity="0.5"
          d="M10.9976 4H12.9976C16.7688 4 18.6544 4 19.826 5.17157C20.8485 6.19404 20.9786 7.76038 20.9952 10.6494V12.2662L19.25 12.0001H4.75003L3.00244 12.2665L3 12.2679V10.6494C3.01656 7.76038 3.14669 6.19404 4.16916 5.17157C5.34073 4 7.22635 4 10.9976 4Z"
          fill="#07132B"/>
    <path
      d="M19 10.5C19 9.31352 18.9981 8.51653 18.919 7.92202C18.8435 7.35407 18.7129 7.11099 18.5543 6.9506C18.3956 6.79022 18.1552 6.65825 17.5934 6.58189C17.0054 6.50196 16.2171 6.5 15.0435 6.5H12.913V10.5L19 10.5Z"
      fill="#07132B"/>
    <path
      d="M11.087 10.5V6.5H8.95652C7.78294 6.5 6.99461 6.50196 6.40656 6.58189C5.84479 6.65825 5.60435 6.79022 5.44571 6.9506C5.28706 7.11099 5.15653 7.35407 5.081 7.92202C5.00194 8.51653 5 9.31352 5 10.5L11.087 10.5Z"
      fill="#07132B"/>
  </svg>
);

export default AccommodationIcon;
