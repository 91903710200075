import React from 'react';

const SubscriptionsIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.96 2C7.378 2 7.716 2.31 7.716 2.692V4.09C8.386 4.078 9.138 4.078 9.984 4.078H14.016C14.862 4.078 15.613 4.078 16.284 4.09V2.692C16.284 2.31 16.622 2 17.04 2C17.458 2 17.796 2.31 17.796 2.692V4.15C19.246 4.256 20.199 4.518 20.899 5.158C21.599 5.799 21.884 6.671 22 8V9H2V8C2.116 6.671 2.401 5.8 3.101 5.158C3.801 4.518 4.753 4.256 6.204 4.15V2.692C6.204 2.31 6.543 2 6.96 2Z"
      fill="#07132B"/>
    <path opacity="0.5"
          d="M22 14V12C22 11.161 21.987 9.665 21.974 9H2.00602C1.99302 9.665 2.00602 11.161 2.00602 12V14C2.00602 17.771 2.00602 19.657 3.17602 20.828C4.34902 22 6.23402 22 10.004 22H14.004C17.774 22 19.658 22 20.83 20.828C22.002 19.656 22 17.771 22 14Z"
          fill="#07132B"/>
    <path
      d="M16.5455 12.5526C16.5455 12.4061 16.488 12.2655 16.3857 12.1619C16.2834 12.0582 16.1447 12 16 12C15.8553 12 15.7166 12.0582 15.6143 12.1619C15.512 12.2655 15.4545 12.4061 15.4545 12.5526V12.56C14.6625 12.7619 14 13.3948 14 14.2717C14 15.3453 14.9927 16.0526 16 16.0526C16.5993 16.0526 16.9091 16.4454 16.9091 16.7283C16.9091 17.0113 16.5993 17.4033 16 17.4033C15.4007 17.4033 15.0909 17.0113 15.0909 16.7283C15.0909 16.5817 15.0334 16.4412 14.9311 16.3375C14.8289 16.2339 14.6901 16.1757 14.5455 16.1757C14.4008 16.1757 14.2621 16.2339 14.1598 16.3375C14.0575 16.4412 14 16.5817 14 16.7283C14 17.6052 14.6625 18.2374 15.4545 18.44V18.4474C15.4545 18.5939 15.512 18.7345 15.6143 18.8381C15.7166 18.9418 15.8553 19 16 19C16.1447 19 16.2834 18.9418 16.3857 18.8381C16.488 18.7345 16.5455 18.5939 16.5455 18.4474V18.44C17.3375 18.2381 18 17.6052 18 16.7283C18 15.6547 17.0073 14.9474 16 14.9474C15.4007 14.9474 15.0909 14.5546 15.0909 14.2717C15.0909 13.9887 15.4007 13.5967 16 13.5967C16.5993 13.5967 16.9091 13.9887 16.9091 14.2717C16.9091 14.4183 16.9666 14.5588 17.0689 14.6625C17.1711 14.7661 17.3099 14.8243 17.4545 14.8243C17.5992 14.8243 17.7379 14.7661 17.8402 14.6625C17.9425 14.5588 18 14.4183 18 14.2717C18 13.3948 17.3375 12.7619 16.5455 12.56V12.5526Z"
      fill="#07132B"/>
  </svg>
);

export default SubscriptionsIcon;
