import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {Select} from 'antd';
import StatusLine from '../StatusLine';
import {StyledTableFiltersSearch, StyledTableFilters, StyledFilterElements, StyledRightSideContent,
  StyledFilterElementsSpace} from './StyledTableFilters';
import {formHelpers, objectHelpers, subscriptionsHelpers} from '../../../../utils/helpers';
import {subscriptionStatusesConstants, subscriptionBudgetStatusesConstants} from '../../../../constants';
import {
  StyledSubscriptionTableSelectOptionSpace
} from '../tabComponents/SubscriptionTableEditableCell/StyledSubscriptionTableEditableCell';


const selectProps = {
  dropdownStyle: {minWidth: 235},
  size: 'large',
  showSearch: true
}

const multipleSelectProps = {
  ...selectProps,
  maxTagCount: 1,
  mode: 'multiple',
  showSearch: true
}

const getStatusOptions = (t) => {
  return [
    {value: '', label: `${t('all')} ${t('Statuses')}`},
    {value: subscriptionStatusesConstants.ACTIVE, label: t('subscriptionStatuses.active')},
    {value: subscriptionBudgetStatusesConstants.OVERBUDGET, label: t('subscriptionStatuses.overBudget')},
    {value: subscriptionStatusesConstants.ABOUT_TO_RENEW, label: t('subscriptionStatuses.aboutToRenew')},
    {value: subscriptionStatusesConstants.TRIAL, label: t('subscriptionStatuses.trial')},
    {value: subscriptionStatusesConstants.PENDING, label: t('subscriptionStatuses.waiting')},
    {value: subscriptionStatusesConstants.INACTIVE, label: t('subscriptionStatuses.inactive')},
  ].map(o => ({
    ...o,
    image: o.value !== '' && <StatusLine color={subscriptionsHelpers.getSubscriptionStatusColor(o.value)} />
  }));
}

const TableFilters = ({
  dispatch,
  defaultSearch,
  defaultQuery,
  filterFields,
  onFilter,
  rightSideContent,

  employees,
  employeeEmail,
  tags,
  ...rest
}) => {
  const [t] = useTranslation('main');

  const tagOptions = useMemo(() => formHelpers.getTagOptions(tags), [tags]);

  const employeeOptions = useMemo(() =>
    formHelpers.getEmployeeOptions({employees, employeeEmail, t}), [employees, employeeEmail, t]);

  const statusOptions = useMemo(() => getStatusOptions(t), [t]);

  const isEnabledField = (key) => filterFields.includes(key);

  const handleOnFilter = (key, value) => onFilter && onFilter({[key]: objectHelpers.isValidValue(value) ? value : undefined});

  const getDefaultValue = (key, defaultValue) => objectHelpers.getObjProp(defaultQuery, key, defaultValue);

  const status = getDefaultValue('status');

  return (
    <StyledTableFilters {...rest}>
      <StyledFilterElements>
        <StyledFilterElementsSpace size='small'>
          {isEnabledField('search') && (
            <StyledTableFiltersSearch
              allowClear
              defaultValue={getDefaultValue('search', '')}
              onSearch={(value) => handleOnFilter('search', value)}
              placeholder={t('search')}
              size='large'
            />
          )}
          {isEnabledField('owners') && (
            <Select
              {...multipleSelectProps}
              defaultValue={getDefaultValue('owners', [])}
              onChange={(value) => handleOnFilter('owners', value)}
              optionLabelProp='optionLabel'
              options={employeeOptions}
              placeholder={t('Owner')}
            />
          )}
          {isEnabledField('tags') && (
            <Select
              {...multipleSelectProps}
              defaultValue={getDefaultValue('tags', [])}
              onChange={(value) => handleOnFilter('tags', value)}
              options={tagOptions}
              placeholder={t('costCentre')}
            />
          )}
          {isEnabledField('status') && (
            <Select
              {...selectProps}
              defaultValue={!status && typeof status !== 'number' ? '' : status}
              onChange={(value) => handleOnFilter('status', value)}
              options={statusOptions.map(option => ({
                ...option,
                label: (
                  <StyledSubscriptionTableSelectOptionSpace>
                    {option.image}
                    {option.label}
                  </StyledSubscriptionTableSelectOptionSpace>
                )
              }))}
            />
          )}
        </StyledFilterElementsSpace>
        {rightSideContent && <StyledRightSideContent>{rightSideContent}</StyledRightSideContent>}
      </StyledFilterElements>
    </StyledTableFilters>
  );
}

TableFilters.propTypes = {
  defaultSearch: PropTypes.string,
  defaultQuery: PropTypes.object,
  filterFields: PropTypes.arrayOf(PropTypes.oneOf(['owners', 'search', 'status', 'tags'])),
  onFilter: PropTypes.func,
  rightSideContent: PropTypes.any
}

TableFilters.defaultProps = {
  defaultSearch: '',
  defaultQuery: {},
  filterFields: ['search']
}

const mapStateToProps = state => {
  const {employees, tags} = state.company;
  const {employee} = state.user;
  return {
    employees,
    employeeEmail: employee.email,
    tags,
  }
}

export default connect(mapStateToProps, null)(TableFilters);
