import React from 'react';

const TeamEnablementIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd"
          d="M15.6025 2.42216C15.3827 2.20239 15.1062 2.21205 14.9545 2.23217C14.8112 2.25119 14.6394 2.30006 14.4758 2.34661L14.3498 2.38242L14.2474 2.31779C14.1088 2.23026 13.96 2.13623 13.8275 2.07867C13.6782 2.01375 13.426 1.93989 13.1612 2.07911C12.9013 2.21567 12.8158 2.46014 12.7804 2.61571C12.7483 2.75726 12.7345 2.93427 12.7214 3.10261L12.7115 3.23012L12.7106 3.24075L12.5991 3.33259C12.4658 3.44226 12.3274 3.55608 12.2293 3.66333C12.1254 3.77695 11.9652 3.99043 12.0059 4.28767C12.048 4.59506 12.2706 4.75445 12.4097 4.83159C12.5356 4.90144 12.7014 4.96261 12.8559 5.01961L12.9648 5.05983L13.005 5.1687C13.062 5.3232 13.1232 5.489 13.193 5.61495C13.2702 5.75405 13.4296 5.97665 13.7369 6.01874C14.0342 6.05944 14.2477 5.8992 14.3613 5.79528C14.4685 5.69717 14.5824 5.55884 14.692 5.42554L14.7839 5.31397L14.7945 5.31313L14.922 5.30319C15.0903 5.29011 15.2674 5.27635 15.4089 5.24417C15.5645 5.2088 15.8089 5.12329 15.9455 4.86346C16.0847 4.59857 16.0109 4.34645 15.9459 4.19709C15.8884 4.06467 15.7944 3.9158 15.7068 3.77723L15.6422 3.67478L15.678 3.54877C15.7246 3.38517 15.7734 3.21343 15.7924 3.07009C15.8126 2.91841 15.8222 2.64192 15.6025 2.42216Z"
          fill="#07132B"/>
    <path
      d="M10.1871 2.16087C10.5405 2.37692 10.6518 2.83856 10.4358 3.19196C10.279 3.44841 10.3183 3.77888 10.5308 3.99142L10.6287 4.0893C11.2174 4.67795 11.4344 5.54273 11.1936 6.3396C11.0737 6.7361 10.6551 6.96036 10.2586 6.84051C9.86213 6.72066 9.63786 6.30208 9.75771 5.90558C9.8386 5.638 9.76571 5.34762 9.56805 5.14996L9.47017 5.05208C8.76765 4.34955 8.63777 3.25725 9.15597 2.40958C9.37202 2.05618 9.83366 1.94483 10.1871 2.16087Z"
      fill="#07132B"/>
    <path
      d="M21.4095 13.5812C21.0478 13.4231 20.6271 13.4895 20.3318 13.7514C19.5225 14.469 18.3449 14.5883 17.4082 14.0475L17.1953 13.9246C16.8366 13.7175 16.7137 13.2588 16.9208 12.9001C17.1279 12.5414 17.5866 12.4185 17.9453 12.6256L18.1582 12.7484C18.5357 12.9664 19.0104 12.9184 19.3366 12.6291C20.0694 11.9793 21.113 11.8145 22.0104 12.2068L22.3019 12.3343C22.6814 12.5002 22.8546 12.9424 22.6886 13.3219C22.5227 13.7015 22.0805 13.8746 21.701 13.7087L21.4095 13.5812Z"
      fill="#07132B"/>
    <path
      d="M12.9166 7.17885C12.7072 6.96938 12.3676 6.96938 12.1581 7.17885C11.9486 7.38832 11.9486 7.72793 12.1581 7.9374C12.3676 8.14687 12.7072 8.14687 12.9166 7.9374C13.1261 7.72793 13.1261 7.38832 12.9166 7.17885Z"
      fill="#07132B"/>
    <path fillRule="evenodd" clipRule="evenodd"
          d="M22.5246 8.86414C22.7805 9.41805 22.6078 10.0469 22.1407 10.514C21.8698 10.7849 21.5068 10.9076 21.1835 10.9651C20.8528 11.0239 20.5032 11.0251 20.197 11.0055C19.8877 10.9857 19.6042 10.9434 19.3992 10.9066C19.2961 10.888 19.2116 10.8707 19.152 10.8577C19.1222 10.8512 19.0985 10.8458 19.0818 10.8419L19.0621 10.8372L19.0563 10.8358L19.0545 10.8354L19.0533 10.8351C18.8733 10.7905 18.7327 10.65 18.6881 10.4699L19.1735 10.3498C18.6881 10.4699 18.6878 10.4688 18.6878 10.4688L18.6874 10.4669L18.686 10.4611L18.6813 10.4414C18.6774 10.4247 18.672 10.401 18.6655 10.3712C18.6526 10.3116 18.6352 10.2271 18.6167 10.1241C18.5798 9.91904 18.5376 9.63547 18.5178 9.32622C18.4982 9.01998 18.4993 8.67041 18.5581 8.33971C18.6156 8.01645 18.7383 7.65343 19.0092 7.38256C19.4763 6.91542 20.1052 6.74276 20.6591 6.99861C21.0793 7.19269 21.3525 7.58546 21.4555 8.06776C21.9378 8.17068 22.3305 8.44397 22.5246 8.86414Z"
          fill="#07132B"/>
    <g opacity="0.5">
      <path
        d="M17.69 4.74357C18.0962 4.8248 18.3596 5.21992 18.2784 5.62609L18.1344 6.34603C17.9362 7.33684 17.2224 8.14551 16.2638 8.46504C15.8159 8.61435 15.4823 8.99222 15.3897 9.4552L15.2457 10.1751C15.1645 10.5813 14.7693 10.8447 14.3632 10.7635C13.957 10.6822 13.6936 10.2871 13.7748 9.88096L13.9188 9.16103C14.117 8.17021 14.8308 7.36154 15.7894 7.04201C16.2374 6.89271 16.5709 6.51484 16.6635 6.05185L16.8075 5.33192C16.8887 4.92575 17.2839 4.66233 17.69 4.74357Z"
        fill="#07132B"/>
      <path
        d="M6.92859 3.96277C7.13805 3.7533 7.47767 3.7533 7.68714 3.96277C7.89661 4.17223 7.89661 4.51185 7.68714 4.72132C7.47767 4.93079 7.13805 4.93079 6.92859 4.72132C6.71912 4.51185 6.71912 4.17223 6.92859 3.96277Z"
        fill="#07132B"/>
    </g>
    <path opacity="0.7"
          d="M19.817 15.3353C19.6076 15.1259 19.2679 15.1259 19.0585 15.3353C18.849 15.5448 18.849 15.8844 19.0585 16.0939C19.2679 16.3033 19.6076 16.3033 19.817 16.0939C20.0265 15.8844 20.0265 15.5448 19.817 15.3353Z"
          fill="#07132B"/>
    <path opacity="0.2"
          d="M17.1581 10.1791C17.3675 9.96961 17.7072 9.96961 17.9166 10.1791C18.1261 10.3885 18.1261 10.7282 17.9166 10.9376C17.7072 11.1471 17.3675 11.1471 17.1581 10.9376C16.9486 10.7282 16.9486 10.3885 17.1581 10.1791Z"
          fill="#07132B"/>
    <path opacity="0.5"
          d="M4.01207 15.7618L5.70156 10.6933C6.46758 8.39525 6.85059 7.24623 7.75684 7.03229C8.6631 6.81835 9.51953 7.67478 11.2324 9.38764L14.6114 12.7666C16.3242 14.4795 17.1807 15.3359 16.9667 16.2422C16.7528 17.1484 15.6038 17.5314 13.3057 18.2975L8.23724 19.987L8.23723 19.987C5.47182 20.9088 4.08912 21.3697 3.35924 20.6398C2.62936 19.9099 3.09026 18.5272 4.01207 15.7618Z"
          fill="#07132B"/>
    <path
      d="M8.8001 7.50424L8.85072 7.25922C8.45761 7.02857 8.111 6.94867 7.75679 7.03229C7.61235 7.06638 7.4812 7.12423 7.35967 7.2067L8.05611 7.35058C7.57757 7.25172 7.41498 7.21808 7.35967 7.2067C7.34804 7.2146 7.3364 7.22279 7.32494 7.23114L7.31964 7.25736C7.31242 7.29328 7.30199 7.34575 7.28885 7.41325C7.26258 7.54824 7.22547 7.74345 7.18165 7.98662C7.09406 8.47264 6.97937 9.15184 6.87078 9.92573C6.65564 11.4589 6.45638 13.4179 6.55904 14.9834C6.62115 15.9306 6.81822 17.1057 6.9941 18.0238C7.08286 18.4872 7.16784 18.8933 7.23066 19.1838C7.26209 19.3291 7.28803 19.4457 7.30619 19.5264L7.32733 19.6195L7.333 19.6441L7.33493 19.6525C7.33494 19.6526 7.33512 19.6533 8.0656 19.4833L7.33493 19.6525L7.47191 20.2412C7.71447 20.1612 7.96933 20.0762 8.23717 19.987L8.90132 19.7656L8.79453 19.3066L8.78944 19.2845L8.76953 19.1968C8.75221 19.1199 8.7272 19.0075 8.69676 18.8667C8.63584 18.585 8.55336 18.1909 8.46732 17.7416C8.29357 16.8346 8.11155 15.7351 8.05582 14.8852C7.96377 13.4814 8.1436 11.6495 8.35623 10.1342C8.46152 9.38377 8.57288 8.72427 8.65787 8.25265C8.70034 8.017 8.73615 7.82867 8.76123 7.69981C8.77376 7.63539 8.78361 7.58587 8.79026 7.55277L8.79777 7.51563L8.7996 7.50665L8.8001 7.50424Z"
      fill="#07132B"/>
    <path
      d="M13.0393 18.3863L11.6162 18.8606L11.5238 18.5826L12.2356 18.3461C11.5238 18.5826 11.5239 18.5827 11.5238 18.5826L11.5229 18.5798L11.5209 18.5738L11.5138 18.552C11.5077 18.5333 11.499 18.5064 11.4881 18.472C11.4663 18.4031 11.4354 18.3042 11.3986 18.1811C11.325 17.9354 11.227 17.5917 11.1288 17.1985C10.9367 16.4293 10.7274 15.407 10.7274 14.5552C10.7274 13.7034 10.9367 12.6811 11.1288 11.9119C11.227 11.5187 11.325 11.175 11.3986 10.9293C11.4354 10.8062 11.4663 10.7073 11.4881 10.6384C11.499 10.604 11.5077 10.5771 11.5138 10.5584L11.5209 10.5366L11.5229 10.5305L11.5235 10.5287C11.5235 10.5287 11.5238 10.5278 12.2356 10.7643L11.5238 10.5278L11.7355 9.89084L12.9224 11.0777C12.921 11.0824 12.9194 11.0872 12.9178 11.0922C12.8982 11.154 12.8698 11.245 12.8356 11.3594C12.767 11.5886 12.6755 11.9095 12.5841 12.2753C12.3971 13.0241 12.2274 13.8973 12.2274 14.5552C12.2274 15.2131 12.3971 16.0863 12.5841 16.8351C12.6755 17.2009 12.767 17.5218 12.8356 17.751C12.8698 17.8654 12.8982 17.9564 12.9178 18.0182C12.9276 18.0491 12.9352 18.0727 12.9403 18.0881L12.9458 18.1052L12.9471 18.109L13.0393 18.3863Z"
      fill="#07132B"/>
  </svg>
);

export default TeamEnablementIcon;
