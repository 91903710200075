import React from 'react';

const CloudServicesIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path opacity="0.5" fillRule="evenodd" clipRule="evenodd"
          d="M10 22H14C17.771 22 19.657 22 20.828 20.828C21.999 19.656 22 17.771 22 14V13.563C22 12.69 22 12.034 21.957 11.5H17.905C16.808 11.5 15.838 11.5 15.057 11.395C14.21 11.281 13.363 11.02 12.672 10.329C11.98 9.637 11.719 8.79 11.605 7.943C11.5 7.162 11.5 6.193 11.5 5.095L11.51 2.261C11.51 2.17833 11.5167 2.097 11.53 2.017C11.121 2 10.636 2 10.03 2C6.239 2 4.343 2 3.172 3.172C2 4.343 2 6.229 2 10V14C2 17.771 2 19.657 3.172 20.828C4.344 21.999 6.229 22 10 22Z"
          fill="#07132B"/>
    <path
      d="M13 16.235C13 17.21 12.105 18 11 18H7.5C6.672 18 6 17.407 6 16.677C6 15.945 6.672 15.353 7.5 15.353C7.6 15.353 7.69667 15.3613 7.79 15.378C7.70909 15.1837 7.66729 14.9754 7.667 14.765C7.667 13.79 8.562 13 9.667 13C10.696 13 11.543 13.685 11.654 14.567C12.438 14.807 13 15.463 13 16.235ZM11.51 2.26L11.5 5.095C11.5 6.192 11.5 7.161 11.605 7.943C11.719 8.79 11.98 9.637 12.672 10.328C13.362 11.019 14.21 11.281 15.057 11.395C15.838 11.5 16.808 11.5 17.905 11.5H21.957C21.9703 11.6547 21.9797 11.8213 21.985 12H22C22 11.732 22 11.598 21.99 11.44C21.914 10.4895 21.5831 9.57713 21.032 8.799C20.938 8.671 20.874 8.595 20.747 8.442C19.954 7.494 18.91 6.312 18 5.5C17.19 4.776 16.079 3.985 15.11 3.339C14.278 2.783 13.862 2.505 13.291 2.299C13.1247 2.24025 12.9558 2.18887 12.785 2.145C12.401 2.05 12.027 2.017 11.5 2.005L11.51 2.26Z"
      fill="#07132B"/>
  </svg>
);

export default CloudServicesIcon;
