import React from 'react';

const EntertainmentIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path opacity="0.5"
          d="M13.1721 8C15.7111 8 16.9806 8 17.9567 8.61367C18.2037 8.76898 18.433 8.95085 18.6405 9.15603C19.4602 9.96679 19.7492 11.203 20.3271 13.6753L21.3496 18.0491C21.709 19.5866 20.7536 21.1243 19.2159 21.483C17.9193 21.7856 16.5854 21.1527 15.9997 19.9569L15.8791 19.7106C15.3413 18.6127 14.2254 17.9167 13.0029 17.9167H10.998C9.77544 17.9167 8.65954 18.6127 8.12178 19.7106L8.00114 19.9569C7.41545 21.1527 6.08162 21.7856 4.78493 21.483C3.24728 21.1243 2.29188 19.5866 2.6513 18.0491L3.67373 13.6753C4.25169 11.203 4.54067 9.96679 5.36041 9.15603C5.56787 8.95085 5.79716 8.76898 6.04418 8.61367C7.02025 8 8.28977 8 10.8288 8H13.1721Z"
          fill="#07132B"/>
    <path
      d="M9.25049 12C9.25049 11.5858 8.9147 11.25 8.50049 11.25C8.08627 11.25 7.75049 11.5858 7.75049 12V12.75H7.00049C6.58627 12.75 6.25049 13.0858 6.25049 13.5C6.25049 13.9142 6.58627 14.25 7.00049 14.25H7.75049V15C7.75049 15.4142 8.08627 15.75 8.50049 15.75C8.9147 15.75 9.25049 15.4142 9.25049 15V14.25H10.0005C10.4147 14.25 10.7505 13.9142 10.7505 13.5C10.7505 13.0858 10.4147 12.75 10.0005 12.75H9.25049V12Z"
      fill="#07132B"/>
    <path
      d="M15.0005 13C15.5528 13 16.0005 12.5523 16.0005 12C16.0005 11.4477 15.5528 11 15.0005 11C14.4482 11 14.0005 11.4477 14.0005 12C14.0005 12.5523 14.4482 13 15.0005 13Z"
      fill="#07132B"/>
    <path
      d="M18.0005 14.5C18.0005 15.0523 17.5528 15.5 17.0005 15.5C16.4482 15.5 16.0005 15.0523 16.0005 14.5C16.0005 13.9477 16.4482 13.5 17.0005 13.5C17.5528 13.5 18.0005 13.9477 18.0005 14.5Z"
      fill="#07132B"/>
    <path
      d="M15.75 4C15.75 3.58579 15.4142 3.25 15 3.25C14.5858 3.25 14.25 3.58579 14.25 4V5C14.25 5.13807 14.1381 5.25 14 5.25H13C12.0335 5.25 11.25 6.0335 11.25 7V8H12.75V7C12.75 6.86193 12.8619 6.75 13 6.75H14C14.9665 6.75 15.75 5.9665 15.75 5V4Z"
      fill="#07132B"/>
  </svg>
);

export default EntertainmentIcon;
