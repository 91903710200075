import styled from 'styled-components';
import {Space} from 'antd';
import AntdModal from '../../../AntdModal';
import {typographyStyles} from '../../../../utils/styles';

const StyledKeylessEnrollModal = styled(AntdModal)`
  &.disabled {
    opacity: 1;
    .ant-modal-body {
      opacity: 0.4;
      cursor: unset;
      pointer-events: none;
    }
  }

  .ant-modal-content {
    .ant-modal-body {
      text-align: center;
      padding: 0;
    }
  }
  
  .ant-modal-footer {
    .ant-btn+.ant-btn:not(.ant-dropdown-trigger) {
      margin-inline-start: 16px;
    }
  }
;
`;

const StyledKeylessEnrollModalContent = styled.div`
  padding: 60px 24px 20px 24px;
  text-align: center;
  
  img, svg {
    &:not(.logo) {
      margin-bottom: 24px;
    }
  }
  
  h3, p {
    max-width: 378px;
    margin: auto;
  }
  
  h3 {
    ${typographyStyles.heading.h5};
    
    color: ${props => props.theme.colors.neutral_10};
    margin-bottom: 8px;
  }
  
  p {
    ${typographyStyles.body.regular};
    
    color: ${props => props.theme.colors.neutral_7};
    font-size: 12px;
    margin-bottom: 0;
  }
`;

const StyledKeylessEnrollModalLink = styled.a`
  .ant-btn {
    font-size: 14px;
    width: 100%;
    height: 50px;
  }
`;

const StyledKeylessEnrollModalLinkSpace = styled(Space)`
  margin-top: 16px;
  width: 100%;
`;


const StyledKeylessEnrollModalResultContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  font-weight: 500;
  padding: 48px;
  text-align: center;

  .ant-result {
    padding: 0;
  }

  .ant-result-title {
    font-size: 20px;
  }
  
  .ant-result-subtitle {
    font-weight: 400;
  }
`;

export {
  StyledKeylessEnrollModal,
  StyledKeylessEnrollModalContent,
  StyledKeylessEnrollModalLink,
  StyledKeylessEnrollModalLinkSpace,
  StyledKeylessEnrollModalResultContainer
};
