import React from 'react';

const AddInvoiceIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.5">
      <path
        d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z"
        fill="#07132B"/>
      <path fillRule="evenodd" clipRule="evenodd"
            d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM12 18C15.3137 18 18 15.3137 18 12C18 8.68629 15.3137 6 12 6C8.68629 6 6 8.68629 6 12C6 15.3137 8.68629 18 12 18ZM15 12C15 13.6569 13.6569 15 12 15C10.3431 15 9 13.6569 9 12C9 10.3431 10.3431 9 12 9C13.6569 9 15 10.3431 15 12Z"
            fill="#07132B"/>
    </g>
    <path
      d="M9.67217 17.5317L11.1967 14.8912C10.7001 14.7536 10.2553 14.4914 9.89804 14.1405L8.37377 16.7806C8.77077 17.0822 9.2065 17.3355 9.67217 17.5317Z"
      fill="#07132B"/>
    <path
      d="M6.0464 12.7498H9.09446C9.0328 12.5101 9 12.2589 9 12C9 11.741 9.03283 11.4896 9.09456 11.2498H6.04644C6.01579 11.4956 6 11.7459 6 12C6 12.2539 6.01577 12.5042 6.0464 12.7498Z"
      fill="#07132B"/>
    <path
      d="M8.37388 7.21929L9.89814 9.85939C10.2555 9.5085 10.7003 9.24637 11.1968 9.10873L9.6723 6.46823C9.20662 6.66442 8.77089 6.91769 8.37388 7.21929Z"
      fill="#07132B"/>
    <path
      d="M12.8031 9.10872L14.3276 6.46821C14.7933 6.66439 15.2291 6.91766 15.6261 7.21926L14.1018 9.85935C13.7445 9.50846 13.2997 9.24635 12.8031 9.10872Z"
      fill="#07132B"/>
    <path
      d="M14.9055 12.7498C14.9672 12.5101 15 12.2589 15 12C15 11.741 14.9672 11.4896 14.9054 11.2498H17.9536C17.9842 11.4956 18 11.7459 18 12C18 12.2539 17.9842 12.5042 17.9536 12.7498H14.9055Z"
      fill="#07132B"/>
    <path
      d="M12.8034 14.8912C13.3 14.7535 13.7447 14.4914 14.102 14.1404L15.6263 16.7806C15.2293 17.0822 14.7936 17.3355 14.3279 17.5317L12.8034 14.8912Z"
      fill="#07132B"/>
  </svg>
);

export default AddInvoiceIcon;
