import React from 'react';

const BillsFeesIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path opacity="0.5"
          d="M7.245 2H16.755C17.914 2 18.493 2 18.961 2.163C19.4021 2.31972 19.8011 2.57586 20.1273 2.91158C20.4535 3.24731 20.698 3.6536 20.842 4.099C21 4.581 21 5.177 21 6.37V20.374C21 21.232 20.015 21.688 19.392 21.118C19.2172 20.9565 18.988 20.8668 18.75 20.8668C18.512 20.8668 18.2828 20.9565 18.108 21.118L17.625 21.56C17.3188 21.8432 16.9171 22.0004 16.5 22.0004C16.0829 22.0004 15.6812 21.8432 15.375 21.56C15.0688 21.2768 14.6671 21.1196 14.25 21.1196C13.8329 21.1196 13.4312 21.2768 13.125 21.56C12.8188 21.8432 12.4171 22.0004 12 22.0004C11.5829 22.0004 11.1812 21.8432 10.875 21.56C10.5688 21.2768 10.1671 21.1196 9.75 21.1196C9.33293 21.1196 8.93121 21.2768 8.625 21.56C8.31879 21.8432 7.91707 22.0004 7.5 22.0004C7.08293 22.0004 6.68121 21.8432 6.375 21.56L5.892 21.118C5.71721 20.9565 5.48798 20.8668 5.25 20.8668C5.01202 20.8668 4.78279 20.9565 4.608 21.118C3.985 21.688 3 21.232 3 20.374V6.37C3 5.177 3 4.58 3.158 4.1C3.458 3.187 4.153 2.471 5.039 2.163C5.507 2 6.086 2 7.245 2Z"
          fill="#07132B"/>
    <path
      d="M15.06 8.5C15.1257 8.42646 15.1762 8.34071 15.2087 8.24764C15.2412 8.15457 15.2551 8.056 15.2495 7.95757C15.244 7.85914 15.2191 7.76278 15.1762 7.67397C15.1334 7.58516 15.0735 7.50566 15 7.44C14.9265 7.37434 14.8407 7.32381 14.7476 7.29129C14.6546 7.25877 14.556 7.2449 14.4576 7.25047C14.3591 7.25604 14.2628 7.28094 14.174 7.32376C14.0852 7.36657 14.0057 7.42646 13.94 7.5L10.929 10.874L10.059 9.9C9.92604 9.75307 9.74033 9.66474 9.54245 9.65429C9.34457 9.64385 9.15059 9.71213 9.0029 9.84424C8.8552 9.97635 8.76579 10.1615 8.75419 10.3594C8.74259 10.5572 8.80975 10.7515 8.941 10.9L10.369 12.5C10.4394 12.5788 10.5256 12.6419 10.622 12.685C10.7184 12.7282 10.8229 12.7505 10.9285 12.7505C11.0341 12.7505 11.1386 12.7282 11.235 12.685C11.3314 12.6419 11.4176 12.5788 11.488 12.5L15.06 8.5ZM7.5 14.75C7.30109 14.75 7.11032 14.829 6.96967 14.9697C6.82902 15.1103 6.75 15.3011 6.75 15.5C6.75 15.6989 6.82902 15.8897 6.96967 16.0303C7.11032 16.171 7.30109 16.25 7.5 16.25H16.5C16.6989 16.25 16.8897 16.171 17.0303 16.0303C17.171 15.8897 17.25 15.6989 17.25 15.5C17.25 15.3011 17.171 15.1103 17.0303 14.9697C16.8897 14.829 16.6989 14.75 16.5 14.75H7.5Z"
      fill="#07132B"/>
  </svg>
);

export default BillsFeesIcon;
