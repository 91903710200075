const systemHashKeysConstants = Object.freeze({
  ADD_MULTIPLE_SUBSCRIPTIONS: '#add-multiple-subscriptions',
  ADD_SINGLE_SUBSCRIPTION: '#add-single-subscription',
  TRANSACTIONS_INVOICE_MISSING: '#transactions-invoice-missing',
  UNMATCHED_INVOICES: '#unmatched-invoices'
});

export {
  systemHashKeysConstants,
};
