import React from 'react';

const AddInvoiceIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path opacity="0.5"
          d="M16 22C14.114 22 13.172 22 12.586 21.414C12.083 20.912 12.012 20.147 12.002 18.75L12 17.25V6.75L12.002 5.25C12.012 3.853 12.083 3.088 12.586 2.586C13.172 2 14.114 2 16 2H18C19.886 2 20.828 2 21.414 2.586C22 3.172 22 4.114 22 6V18C22 19.886 22 20.828 21.414 21.414C20.828 22 19.886 22 18 22H16Z"
          fill="#07132B"/>
    <path
      d="M15 8.25H12V9.75H15C15.1989 9.75 15.3897 9.67098 15.5303 9.53033C15.671 9.38968 15.75 9.19891 15.75 9C15.75 8.80109 15.671 8.61032 15.5303 8.46967C15.3897 8.32902 15.1989 8.25 15 8.25ZM14 5.25H12.002L12 6.75H14C14.1989 6.75 14.3897 6.67098 14.5303 6.53033C14.671 6.38968 14.75 6.19891 14.75 6C14.75 5.80109 14.671 5.61032 14.5303 5.46967C14.3897 5.32902 14.1989 5.25 14 5.25ZM14 11.25H12V12.75H14C14.1989 12.75 14.3897 12.671 14.5303 12.5303C14.671 12.3897 14.75 12.1989 14.75 12C14.75 11.8011 14.671 11.6103 14.5303 11.4697C14.3897 11.329 14.1989 11.25 14 11.25ZM15 14.25H12V15.75H15C15.1989 15.75 15.3897 15.671 15.5303 15.5303C15.671 15.3897 15.75 15.1989 15.75 15C15.75 14.8011 15.671 14.6103 15.5303 14.4697C15.3897 14.329 15.1989 14.25 15 14.25ZM14 17.25H12L12.002 18.75H14C14.1989 18.75 14.3897 18.671 14.5303 18.5303C14.671 18.3897 14.75 18.1989 14.75 18C14.75 17.8011 14.671 17.6103 14.5303 17.4697C14.3897 17.329 14.1989 17.25 14 17.25ZM8 14.98V7C7.04796 7.39015 6.02888 7.59057 5 7.59C3.97112 7.59057 2.95204 7.39015 2 7V14.98C2 15.602 2 15.914 2.038 16.22C2.08277 16.58 2.1666 16.9341 2.288 17.276C2.39 17.566 2.529 17.845 2.808 18.402L4.276 21.339C4.34311 21.4736 4.4464 21.5868 4.57429 21.666C4.70218 21.7451 4.8496 21.787 5 21.787C5.1504 21.787 5.29782 21.7451 5.42571 21.666C5.5536 21.5868 5.65689 21.4736 5.724 21.339L7.192 18.402C7.471 17.845 7.61 17.567 7.712 17.276C7.83347 16.9338 7.9173 16.5794 7.962 16.219C8 15.914 8 15.602 8 14.98Z"
      fill="#07132B"/>
    <path opacity="0.5"
          d="M5 2C5.79565 2 6.55871 2.31607 7.12132 2.87868C7.68393 3.44129 8 4.20435 8 5V7C7.04789 7.3898 6.02881 7.58988 5 7.589C3.97119 7.58988 2.95211 7.3898 2 7V5C2 4.20435 2.31607 3.44129 2.87868 2.87868C3.44129 2.31607 4.20435 2 5 2Z"
          fill="#07132B"/>
  </svg>
);

export default AddInvoiceIcon;
