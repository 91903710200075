import styled from 'styled-components';
import AntdModal from '../../../AntdModal';

const StyledExportModal = styled(AntdModal)`
  .ant-picker {
    width: 100%;
  }
  .ant-space {
    text-align: center;
    width: 100%;
  }
`;

const StyledExportModalDatePickerPanel = styled.div`
  position: relative;
  
  .ant-picker-header-super-prev-btn,
  .ant-picker-header-super-next-btn {
    display: none !important;
  }
`;

export {
  StyledExportModal,
  StyledExportModalDatePickerPanel,
}
