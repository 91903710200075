import React from 'react';

const AdvertisingIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.7 16.5H7.5V14.5H9.5L9.7 15.25C9.85 15.9 9.35 16.5 8.7 16.5ZM18.5 18C18.5 18 12.6 14.5 9.5 14.5V7.5C12.4 7.5 18.5 4 18.5 4V18Z"
      fill="#07132B"/>
    <path
      d="M4.5 13.5C5.88071 13.5 7 12.3807 7 11C7 9.61929 5.88071 8.5 4.5 8.5C3.11929 8.5 2 9.61929 2 11C2 12.3807 3.11929 13.5 4.5 13.5Z"
      fill="#07132B"/>
    <path
      d="M20 9.5H18.5V12.5H20C20.85 12.5 21.5 11.85 21.5 11C21.5 10.15 20.85 9.5 20 9.5ZM9.29998 20.6C8.84998 20.9 8.04998 21.2 6.99998 21.3C6.69998 21.35 6.39998 21.15 6.29998 20.8L4.09998 13.95C4.09998 13.95 8.49998 10.85 8.49998 14.5C8.49998 17.25 9.24998 18.7 9.59998 19.25C9.84998 19.6 9.84998 20.05 9.59998 20.4C9.49998 20.5 9.39998 20.55 9.29998 20.6Z"
      fill="#07132B"/>
    <path d="M4.5 14.5H9.5V7.5H4.5C3.95 7.5 3.5 7.95 3.5 8.5V13.5C3.5 14.05 3.95 14.5 4.5 14.5Z" fill="#07132B"/>
    <path d="M19 19C18.45 19 18 18.55 18 18V4C18 3.45 18.45 3 19 3C19.55 3 20 3.45 20 4V18C20 18.55 19.55 19 19 19Z"
          fill="#07132B"/>
  </svg>
);

export default AdvertisingIcon;
