import React from 'react';

const TravelIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path opacity="0.5"
          d="M2 14C2 10.229 2 8.343 3.172 7.172C4.344 6.001 6.229 6 10 6H14C17.771 6 19.657 6 20.828 7.172C21.999 8.344 22 10.229 22 14C22 17.771 22 19.657 20.828 20.828C19.656 21.999 17.771 22 14 22H10C6.229 22 4.343 22 3.172 20.828C2.001 19.656 2 17.771 2 14Z"
          fill="#07132B"/>
    <path
      d="M11.948 1.25C11.049 1.25 10.3 1.25 9.706 1.33C9.078 1.414 8.511 1.6 8.056 2.055C7.6 2.511 7.414 3.078 7.33 3.705C7.25 4.3 7.25 5.05 7.25 5.948V6H8.75C8.75 5.036 8.752 4.388 8.817 3.905C8.879 3.444 8.986 3.246 9.117 3.116C9.248 2.986 9.444 2.879 9.905 2.816C10.388 2.752 11.036 2.75 12 2.75C12.964 2.75 13.612 2.752 14.095 2.817C14.556 2.879 14.754 2.986 14.884 3.117C15.014 3.248 15.121 3.444 15.184 3.905C15.248 4.388 15.25 5.036 15.25 6H16.75V5.948C16.75 5.05 16.75 4.3 16.67 3.706C16.586 3.078 16.4 2.511 15.944 2.056C15.489 1.6 14.922 1.414 14.294 1.33C13.7 1.25 12.95 1.25 12.052 1.25H11.948ZM10 15H6C5.529 15 5.293 15 5.146 15.146C5 15.293 5 15.53 5 16V17C5 17.471 5 17.707 5.146 17.854C5.293 18 5.53 18 6 18H10C10.471 18 10.707 18 10.854 17.854C11 17.707 11 17.47 11 17V16C11 15.529 11 15.293 10.854 15.146C10.707 15 10.47 15 10 15Z"
      fill="#07132B"/>
    <path
      d="M5.25 21.797C5.689 21.876 6.185 21.924 6.75 21.953V18H6C5.626 18 5.4 18 5.25 17.927V21.797ZM5.25 15.073V6.203C5.689 6.124 6.185 6.076 6.75 6.047V15H6C5.626 15 5.4 15 5.25 15.073ZM17.25 21.953C17.7528 21.9325 18.2538 21.8804 18.75 21.797V6.203C18.2538 6.1196 17.7528 6.0675 17.25 6.047V21.953Z"
      fill="#07132B"/>
  </svg>
);

export default TravelIcon;
