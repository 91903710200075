import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {connect} from 'react-redux';
import UnmatchedInvoicesModal from '../pages/InvoicesPage/UnmatchedInvoicesModal';
import InvoiceMissingModal from '../pages/TransactionsPage/InvoiceMissingModal';
import routes from '../../routes/routes.json';
import {systemHashKeysConstants} from '../../constants';

const {TRANSACTIONS_INVOICE_MISSING, UNMATCHED_INVOICES} = systemHashKeysConstants;

const defaultModalProps = {loading: false, open: false};

const HashWindowManager = ({
  isAuthenticated,
  children,
  companyState
}) => {
  const navigate = useNavigate();
  const [unmatchedInvoicesModalProps, setUnmatchedInvoicesModalProps] = useState(defaultModalProps);
  const [transactionsInvoiceMissingModalProps, setTransactionsInvoiceMissingModalProps] = useState(defaultModalProps);

  const { hash } = useLocation();

  const handleOpenModal = (setter, path) => {
    if (!isAuthenticated) return;
    setter && setter({...defaultModalProps, open: true});
    path && navigate(path);
  }

  useEffect(() => {
    if (hash) {
      const hashCallbacks = {
        [TRANSACTIONS_INVOICE_MISSING]: () => handleOpenModal(setTransactionsInvoiceMissingModalProps, `${routes.transactionsList}?has_invoice=false`),
        [UNMATCHED_INVOICES]: () => handleOpenModal(setUnmatchedInvoicesModalProps, `${routes.invoicesList}#unmatched`),
      }
      if (hashCallbacks.hasOwnProperty(hash)) {
        hashCallbacks[hash](hash);
      }
    }
  }, [hash, isAuthenticated]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleCancelInvoiceMissingModal = () => setTransactionsInvoiceMissingModalProps(defaultModalProps);

  const handleCancelUnmatchedInvoicesModal = () => setUnmatchedInvoicesModalProps(defaultModalProps);

  const isOpen = (getter) => companyState && getter?.open;

  return (
    <>
      {children}

      <InvoiceMissingModal
        {...transactionsInvoiceMissingModalProps}
        onCancel={handleCancelInvoiceMissingModal}
        onOk={handleCancelInvoiceMissingModal}
        open={isOpen(transactionsInvoiceMissingModalProps)}
      />

      <UnmatchedInvoicesModal
        {...unmatchedInvoicesModalProps}
        onCancel={handleCancelUnmatchedInvoicesModal}
        onOk={handleCancelUnmatchedInvoicesModal}
        open={isOpen(unmatchedInvoicesModalProps)}
      />
    </>
  );
};

const mapStateToProps = state => {
  const {banking, user} = state;
  return {
    isAuthenticated: user?.isAuth,
    companyState: banking?.companyState
  }
}

export default connect(mapStateToProps, null)(HashWindowManager);
