import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import React, {useEffect, useMemo, useState} from 'react';
import {connect} from 'react-redux';
import {UnmountClosed} from 'react-collapse';
import {
  StyledAddInvoiceModal,
  StyledAddInvoiceModalSpace,
  StyledAddInvoiceModalLineDescription,
  StyledAddInvoiceModalRadio,
  StyledAddInvoiceModalRadioGroupSpace,
  StyledAddInvoiceModalStickyContainer
} from './StyledAddInvoiceModal';
import {Button, Radio, Space} from 'antd';
import SpinSmall from '../../../SpinSmall';
import TransactionDetailsHeader from '../TransactionDetailsHeader';
import InvoiceUpload from '../InvoiceUpload';
import ImagePreviewTooltip from '../ImagePreviewTooltip';
import InvoiceAttachmentPreview from '../InvoiceAttachmentPreview';
import InvoiceTypeSelect from '../../InvoicesPage/InvoiceTypeSelect';
import invoicePlaceholderImg from '../../../../static/images/pages/transactions/invoice-placeholder.png';
import {invoicesActions} from '../../../../state/actions';
import {invoiceStatusConstants, invoiceTypeConstants} from '../../../../constants';
import {amountHelpers, dateHelpers, fileHelpers} from '../../../../utils/helpers';

const defaultOkButtonProps = {
  loading: false
};

const AddInvoiceModal = ({
  afterClose,
  defaultAttachment,
  defaultInvoiceType,
  mode,
  onOk,
  open,
  transaction,
  getInvoices,
  getInvoiceDetails,
  ...rest
}) => {
  const [t] = useTranslation(['main', 'transactions']);
  const [okButtonProps, setOkButtonProps] = useState(defaultOkButtonProps);
  const [file, setFile] = useState(null);
  const [uploadFiles, setUploadFiles] = useState([]);
  const [selectedInvoice, setSelectedInvoice] = useState(undefined);
  const [invoices, setInvoices] = useState({
    data: [],
    loading: false,
    loaded: false,
    pagination: null
  });
  const [invoiceType, setInvoiceType] = useState(defaultInvoiceType);
  const [invoicesPreviews, setInvoicesPreviews] = useState({loading: false, invoices: {}});
  const [previewProps, setPreviewProps] = useState({file: undefined, open: false});

  const isAvailableInvoicesList = useMemo(() => invoiceType && invoiceType !== invoiceTypeConstants.NO_RECEIPT, [invoiceType]);

  const isDisableOkButton = useMemo(() => {
    let disabled = true;
    if (invoiceType === invoiceTypeConstants.NO_RECEIPT) {
      disabled = false;
    } else if (invoiceType && (file || selectedInvoice)) {
      disabled = false;
    }
    return disabled;
  }, [invoiceType, file, selectedInvoice]);

  useEffect(() => setInvoiceType(defaultInvoiceType), [defaultInvoiceType]);

  const tooltipContent = useMemo(() => {
    return previewProps.open && (
      <>
        {invoicesPreviews.loading && 'Loading...'}
        {previewProps.file && (
          <InvoiceAttachmentPreview
            attachment={previewProps.file}
            className={invoicesPreviews.loading && 'd-none'}
          />
        )}
      </>
    );
  }, [previewProps, invoicesPreviews]);

  useEffect(() => {
    if (open && !invoices.loaded) {
      loadInvoices();
    }
  }, [getInvoices, open]); // eslint-disable-line react-hooks/exhaustive-deps

  const loadInvoices = () => {
    const query = {
      cursor: invoices.pagination?.cursor || undefined,
      per_page: 10,
      sort_order: 'desc',
      status_not_eq: invoiceStatusConstants.MATCHED
    };
    setInvoices({...invoices, loading: true});
    getInvoices(
      query,
      (response) => {
        const data = response.invoices || [];
        setInvoices({
          ...invoices,
          data: [...invoices.data, ...data],
          pagination: response.pagination,
          loaded: true,
          loading: false
        });
      },
      () => setInvoices({...invoices, loading: false})
    );
  }


  const handleAfterClose = (e) => {
    afterClose && afterClose(e);
    file && setFile(null);
    setUploadFiles([]);
    selectedInvoice && setSelectedInvoice(null);
    invoiceType && setInvoiceType(defaultInvoiceType);
  }

  const handleOnFileChange = async (files) => {
    if (files.length > 0) {
      const fileBase64 = await fileHelpers.getBase64(files[0].originFileObj);
      setFile({
        file: fileBase64,
        name: files[0].name
      });
    } else {
      setFile(null);
    }
    selectedInvoice && setSelectedInvoice(undefined);
    setUploadFiles(files);
  }

  const handleSubmitFile = () => {
    if (onOk && !isDisableOkButton) {
      setOkButtonProps({...okButtonProps, loading: true});
      onOk(
        {
          expense_type: invoiceType,
          file,
          invoice: selectedInvoice
        },
        () => setOkButtonProps({...okButtonProps, loading: false}),
        () => setOkButtonProps({...okButtonProps, loading: false})
      );
    }
  }

  const handleRadioChange = (e) => setSelectedInvoice(e.target.value);

  const handleDisplayPreview = (id) => {
    if (invoicesPreviews.loading) return;
    const previewFile = invoicesPreviews.invoices[id] || undefined;
    if (previewFile) {
      setPreviewProps({...previewProps, open: true, file: previewFile})
    } else {
      setInvoicesPreviews({...invoicesPreviews, loading: true});
      getInvoiceDetails(
        id,
        (invoice) => {
          const invoiceFile = {
            file: invoice.file_url,
            name: invoice.file_name
          };
          setPreviewProps({...previewProps, open: true, file: invoiceFile})
          setInvoicesPreviews({
            ...invoicesPreviews,
            loading: false,
            invoices: {
              ...invoicesPreviews.invoices,
              [id]: invoiceFile
            }
          })
        }
      );
    }
  }

  const handleChangeInvoiceType = (e, value) => setInvoiceType(value);

  const cancelButtonProps = {
    style: {display: 'none'}
  };

  return (
    <StyledAddInvoiceModal
      afterClose={handleAfterClose}
      cancelButtonProps={cancelButtonProps}
      okButtonProps={{...okButtonProps, disabled: isDisableOkButton}}
      okText={t('done')}
      onOk={handleSubmitFile}
      open={open}
      title={`${t(mode)} ${t('invoice')}`}
      {...rest}
    >
      <StyledAddInvoiceModalSpace>
        <StyledAddInvoiceModalStickyContainer>
          <TransactionDetailsHeader
            transaction={transaction}
          />
          <UnmountClosed isOpened={isAvailableInvoicesList}>
            <StyledAddInvoiceModalLineDescription>
              {t('uploadNewOrSelectFromTheList')}
            </StyledAddInvoiceModalLineDescription>
          </UnmountClosed>
        </StyledAddInvoiceModalStickyContainer>

        <StyledAddInvoiceModalSpace>
          <UnmountClosed isOpened={isAvailableInvoicesList}>
            <InvoiceUpload
              className='uploader'
              defaultFileList={uploadFiles}
              onChange={handleOnFileChange}
            />
          </UnmountClosed>
          <InvoiceTypeSelect
            className='invoice-type-select'
            value={invoiceType}
            onChange={handleChangeInvoiceType}
          />
          <UnmountClosed isOpened={isAvailableInvoicesList}>
            <SpinSmall spinning={invoices.loading && invoices.pagination === null}>
              <Radio.Group
                onChange={handleRadioChange}
                value={selectedInvoice}
              >
                <StyledAddInvoiceModalRadioGroupSpace
                  direction='vertical'
                  size='small'
                >
                  {invoices.data.map((invoice, index) => {
                    const invoiceId = invoice.id;
                    const vendor = invoice.vendor;
                    const amount = invoice?.amount || 0;
                    const createdDate = invoice?.created_at || undefined;
                    const date = createdDate ? dateHelpers.getDateWithMonth(createdDate, 'DD MMM YYYY') : '';
                    return (
                      <ImagePreviewTooltip
                        key={`image-preview-${index}`}
                        trigger='hover'
                        mouseEnterDelay={1}
                        mouseLeaveDelay={1}
                        title={tooltipContent}
                      >
                        <div
                          key={`radio-${index}`}
                          onMouseOver={() => handleDisplayPreview(invoiceId)}
                          onMouseLeave={() => setPreviewProps({...previewProps, open: false})}
                        >
                          <StyledAddInvoiceModalRadio
                            value={invoiceId}
                          >
                            <Space size='small'>
                              <img
                                alt='invoice placeholer'
                                className='placeholder'
                                src={invoicePlaceholderImg}
                              />
                              <Space direction='vertical' size={2}>
                                <span className='vendor'>
                                  {vendor}
                                </span>
                                <Space size='small'>
                                  <span className='date'>
                                    {date}
                                  </span>
                                  <span className='price'>
                                    {amountHelpers.getAmountWithCurrencyCode(amount)}
                                  </span>
                                </Space>
                              </Space>
                            </Space>
                          </StyledAddInvoiceModalRadio>
                        </div>
                      </ImagePreviewTooltip>
                    )
                  })}
                </StyledAddInvoiceModalRadioGroupSpace>
              </Radio.Group>
            </SpinSmall>
            {invoices.pagination?.cursor && (
              <div className='d-flex justify-center mt-20'>
                <Button
                  loading={invoices.loading}
                  onClick={loadInvoices}
                >
                  {t('loadMore')}
                </Button>
              </div>
            )}
          </UnmountClosed>
        </StyledAddInvoiceModalSpace>
      </StyledAddInvoiceModalSpace>
    </StyledAddInvoiceModal>
  );
}

AddInvoiceModal.propTypes = {
  afterClose: PropTypes.func,
  defaultAttachment: PropTypes.shape({
    contentType: PropTypes.string,
    file: PropTypes.string,
    name: PropTypes.string
  }),
  defaultInvoiceType: PropTypes.oneOf(Object.values(invoiceTypeConstants)),
  mode: PropTypes.oneOf(['add', 'edit']),
  transaction: PropTypes.object,
  onOk: PropTypes.func,
  open: PropTypes.bool.isRequired
}

AddInvoiceModal.defaultProps = {
  defaultInvoiceType: undefined,
  mode: 'add'
}

const mapDispatchToProps = {
  getInvoices: invoicesActions.getInvoices,
  getInvoiceDetails: invoicesActions.getInvoice
}

export default connect(null, mapDispatchToProps)(AddInvoiceModal);
