import React, {useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import {Button, DatePicker, Form, Space} from 'antd';
import {StyledExportModal, StyledExportModalDatePickerPanel} from './StyledExportModal';
import RadioGroupIcon from '../../../RadioGroupIcon';
import SpinSmall from '../../../SpinSmall';
import {FileCsvIcon, FilePdfIcon, FileZipIcon} from '../../../../icons';
import {ReactComponent as ExportSvgImage} from '../../../../static/images/pages/transactions/export-transactions.svg';
import {objectHelpers} from '../../../../utils/helpers';

const {Item} = Form;

const endOfMonthDate = dayjs().endOf('month');

const defaultFormValues = {
  format: 'pdf',
  date: dayjs(),
  date_range: []
}

const pickerProps = {
  size: 'middle'
}

const ExportModal = ({
  enableFormatField,
  handleCancel,
  handleOk,
  loading,
  open,
  title,
  ...rest
}) => {
  const [form] = Form.useForm();
  const [t] = useTranslation('main');
  const [formValues, setFormValues] = useState(defaultFormValues);

  const format = Form.useWatch('format', form);
  const date = Form.useWatch('date', form);
  const dateRange = Form.useWatch('date_range', form);

  const disabledOkButton = useMemo(() => {
    let disabled;
    if (loading) {
      disabled = true;
    } else {
      disabled = format === 'pdf' ? !objectHelpers.isValidValue(date) : dateRange ? dateRange.length < 2 : true;
    }
    return disabled;
  }, [date, dateRange, format, loading]);

  useEffect(() => {
    if (!open) {
      setFormValues(defaultFormValues);
      form.setFieldsValue(defaultFormValues);
    }
  }, [open]); // eslint-disable-line react-hooks/exhaustive-deps

  const onValuesChange = (values) => {
    setFormValues({
      ...formValues,
      ...values
    });
  }

  const footer = [
    <Button
      key='ok'
      disabled={disabledOkButton}
      onClick={() => (!disabledOkButton && handleOk) && handleOk(formValues)}
      type='primary'
      size='large'
    >
      {t('export')}
    </Button>
  ];

  const radioOptions = [
    {icon: <FilePdfIcon />, label: 'PDF', value: 'pdf'},
    {icon: <FileCsvIcon />, label: 'Spreadsheet', value: 'csv'},
    {icon: <FileZipIcon />, label: 'ZIP Invoices', value: 'zip'}
  ];

  return (
    <StyledExportModal
      forceRender
      footer={footer}
      onCancel={handleCancel}
      open={open}
      title={title || `${t('export')}`}
      width={488}
      {...rest}
    >
      <SpinSmall spinning={loading}>
        <Space direction='vertical' size='middle'>
          <ExportSvgImage />
          <Form
            form={form}
            initialValues={formValues}
            layout='vertical'
            onValuesChange={onValuesChange}
          >
            {format === 'pdf' ? (
              <Item
                label={t('selectDate')}
                name='date'
              >
                <DatePicker
                  {...pickerProps}
                  disabledDate={(current) => current.isAfter(endOfMonthDate)}
                  format='MMM YYYY'
                  picker='month'
                  placeholder={t('selectDate')}
                />
              </Item>
            ) : (
              <Item
                label={t('selectDate')}
                name='date_range'
              >
                <DatePicker.RangePicker
                  {...pickerProps}
                  disabledDate={(current) => current.isAfter(dayjs())}
                  panelRender={(panelNode) => (
                    <StyledExportModalDatePickerPanel>
                      {panelNode}
                    </StyledExportModalDatePickerPanel>
                  )}
                  format='DD MMM YYYY'
                  superNextIcon={false}
                  superPrevIcon={false}
                />
              </Item>
            )}
            {enableFormatField && (
              <Item
                label={`${t('select')} ${t('format')}`}
                name='format'
                style={{marginBottom: 0}}
              >
                <RadioGroupIcon options={radioOptions} />
              </Item>
            )}
          </Form>
        </Space>
      </SpinSmall>
    </StyledExportModal>
  );
}

ExportModal.propTypes = {
  enableFormatField: PropTypes.bool,
  handleCancel: PropTypes.func,
  handleOk: PropTypes.func,
  loading: PropTypes.bool,
  open: PropTypes.bool
}

ExportModal.defaultProps = {
  enableFormatField: true,
  loading: false,
  open: false
}

export default ExportModal;


